import $ from 'jquery';
// import Anchor from '../Components/_Anchor.js';
// import Swiper from '../Components/_Slider.js';

var $window = $(window);
var $bodyHtml = $('body,html');
var $html = $('html');
var $body = $('body');
var breakPoint1 = 767;

$(window).on('load' , function(){
	if ($window.innerWidth() < 768) {
		$('.c-header').addClass('is-sp');
	}
})

/* ======================================
body fix
====================================== */
var scrollPosi;

function bodyFix() {
	scrollPosi = $(window).scrollTop();
	$body.css({
		position: 'fixed',
		width: '100%',
		'z-index': '1',
		top: -scrollPosi,
	});
}

function bodyFixReset() {
	$body.css({
		position: 'relative',
		width: 'auto',
		top: '0',
	});
	$bodyHtml.scrollTop(scrollPosi);
}

/* ======================================
Header menu
====================================== */

$('.js-toggle').on('click', function () {
	let parent = $(this).parent();
	if (parent.hasClass('is-active')) {
		parent.removeClass('is-active');
		bodyFixReset();
	} else {
		parent.addClass('is-active');
		bodyFix();
	}
});

/* ======================================
Anchor Link
====================================== */

$('a[href*=\\#]:not([href=\\#])').click(function () {
	if (
		location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
		location.hostname == this.hostname
	) {
		var $target = $(this.hash);
		$target =
			($target.length && $target) || $('[name=' + this.hash.slice(1) + ']');
		var offsetTop = $('.c-header').height();
		console.log(offsetTop);
		if ($target.length) {
			var targetOffset = $target.offset().top - offsetTop;
			if ($window.innerWidth() < 768) {
				$('.js-toggle').parent().removeClass('is-active');
				bodyFixReset();
			}
			setTimeout(() => {
				var targetOffset = $target.offset().top - offsetTop;
				$('html,body').animate({ scrollTop: targetOffset }, 1000);
			}, 200);
			return false;
		}
	}
});

$('.c-form1__input, .c-form1__checkbox').each(function () {
	if ($(this).find('span.error').length == 1) {
		$(this).addClass('is-error');
	}
});
